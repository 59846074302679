<template>
  <liffMemberItemTemplate :main="main" :control="control" :deleteAction="deleteMember" :refresh="refresh"
    :changeRoleAction="changeRole" :member="member" :memberRoles="memberRoles" :memberRole="memberRole">
    <template v-slot:avatar>
      <memberAvatar />
    </template>
    <template v-slot:content>
      <div class="pl-3 content">
        <div class="mb-1 text-lg text-break-word">{{ name }}</div>
        <div class="mb-0 text-sm-xs text text-break-word">{{ email }}</div>
      </div>
    </template>
  </liffMemberItemTemplate>
</template>

<script>
export default {
  components: {
    liffMemberItemTemplate: () =>
      import("@/modules/member/components/liffMemberItemTemplate.vue"),
    memberAvatar: () => import("@/modules/member/components/memberAvatar.vue"),
  },
  props: {
    role: {
      type: String,
      default: "provider",
    },
    // 該會員是否主要管理員
    main: {
      type: Boolean,
      default: false,
    },
    control: {
      type: Boolean,
      default: false,
    },
    member: Object,
    providerId: String,
    storeId: String,
    deleteItemCallback: Function,
    refresh: Function,
    memberRoles: Array,
    memberRole: String,
  },
  computed: {
    memberId() {
      return this.member.id
    },
    avatar() {
      return this.member.avatar;
    },
    name() {
      return this.member.name;
    },
    email() {
      return this.member.email;
    },
    showRemoveBtn() {
      if (this.main) return false;
      if (!this.control) return false;
      return true;
    },
  },
  methods: {
    async deleteMember() {
      this.$store.dispatch("loading/active");
      try {
        if (this.role == 'provider') {
          await this.$api.collection.providerMemberApi.delete(this.providerId, this.memberId)
        } else {
          await this.$api.collection.storeMemberApi.delete(this.storeId, this.memberId)
        }
        this.deleteItemCallback(this.member)
        this.$snotify.success("刪除成功");
      } catch (err) {
        console.error(err)
        this.$snotify.error("刪除失敗");
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async changeRole(item) {
      const data = {
        roles: item == 'co_manager' ? [] : [item]
      }

      this.$store.dispatch("loading/active");
      try {
        if (this.role == 'provider') {
          await this.$api.collection.providerMemberApi.updateRole(this.providerId, this.memberId, data)
        } else {
          await this.$api.collection.storeMemberApi.updateRole(this.storeId, this.memberId, data)
        }
        this.refresh()
        this.$snotify.success("更改成功");
      } catch (err) {
        console.error(err)
        this.$snotify.error("更改失敗");
      } finally {
        this.$store.dispatch("loading/close");
      }
      console.log(data);
    }
  },
};
</script>

<style lang="sass">
.content 
  flex: 1
  width: 0
</style>